import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/et';

import { articleBlock, articleBlockList, articleContent, articleMeta } from '../css/modules/articleBlock.module.css';
import { articleListLink } from '../css/modules/articleListLink.module.css';

moment.locale('et');

function excerpt(text) {
  if (text.length > 200) {
    return text.substring(0, 200) + '…';
  }

  return text;
}

export default function PodcastListItem(props) {
  const {
    podcast,
  } = props;

  const articleClasses = [articleBlock, articleBlockList];

  return (
    <article className={articleClasses.join(' ')}>
      <Link to={`/podcasts/${podcast.id}`} className={articleListLink}>
        <div className={articleContent}>
          <h1>
            { podcast.title }
            { ' ' }
            <span className={articleMeta}>
              {moment(podcast.datetime).format('D MMM H:mm')}
            </span>
          </h1>
          { excerpt(podcast.excerpt) }
        </div>
      </Link>
    </article>
  );
}

import React from 'react';
import { connect } from 'react-redux';

import { fetchGames } from '../actions/games';

import GameListItem from '../components/GameListItem';
import LoadingIndicator from '../components/LoadingIndicator';

import { appContent, appContentGames } from '../css/modules/appContent.module.css';

class GamesPageList extends React.Component {
  componentDidMount() {
    this.props.fetchGames();
  }

  render() {
    const { loading, podcasts } = this.props;

    const appContentClasses = [appContent, appContentGames];

    return (
      <main className={ appContentClasses.join(' ') }>
        {
          loading ? <LoadingIndicator /> :
            podcasts.map(a => <GameListItem key={a.id} game={a} />)
        }
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    podcasts: state.games.items,
    loading: state.games.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGames: () => dispatch(fetchGames()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesPageList);

import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { articleOverlay, articleOverlayContent, overlayCloseButton } from '../css/modules/articleOverlay.module.css';
import { articleBlock, articleContent, articleMeta, articleExcerpt, articleMedia } from '../css/modules/articleBlock.module.css';

import CloseIcon from '../components/icons/CloseIcon';

import podcasts from '../fixtures/podcasts';

function ArticleOverlay(props) {
  const { article } = props;

  return (
    <section className={articleOverlay}>
      <div className={articleOverlayContent}>
        <Link to="/podcasts" className={overlayCloseButton}>
          <CloseIcon />
        </Link>
        <article className={articleBlock}>
          <div className={articleMedia}>
            <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/589337028&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
          </div>
          <div className={ articleContent }>
            <h1>
              { article.title }
              { ' ' }
              <span className={articleMeta}>
                {moment(article.datetime).format('D MMM H:mm')}
              </span>
            </h1>
            <section className={articleExcerpt}>
              { article.excerpt }
            </section>
            { article.body }
          </div>
        </article>
      </div>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    article: podcasts.find(p => parseInt(p.id, 10) === parseInt(ownProps.match.params.id, 10)),
  };
};

export default connect(mapStateToProps)(withRouter(ArticleOverlay));

import {
  FETCH_PODCASTS,
  RECEIVE_PODCASTS,
  SET_PODCAST_SEARCH_TERM,
  ADD_PODCAST_CATEGORY,
  REMOVE_PODCAST_CATEGORY,
  SET_PODCAST_HOTNESS,
} from '../constants/podcasts';

import podcasts from '../fixtures/podcasts';

export function setSearchTerm(term = '') {
  return {
    type: SET_PODCAST_SEARCH_TERM,
    payload: {
      term,
    },
  };
}

export function addCategory(category) {
  return {
    type: ADD_PODCAST_CATEGORY,
    payload: {
      category,
    },
  };
}

export function removeCategory(category) {
  return {
    type: REMOVE_PODCAST_CATEGORY,
    payload: {
      category,
    },
  };
}

export function setHotness(hotness) {
  return {
    type: SET_PODCAST_HOTNESS,
    payload: {
      hotness,
    },
  };
}

export function fetchPodcasts() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PODCASTS,
    });

    setTimeout(() => {
      dispatch({
        type: RECEIVE_PODCASTS,
        payload: {
          podcasts,
        },
      });
    }, 500);
  };
}

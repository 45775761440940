import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import NewsPage from './NewsPage';
import PodcastsPage from './PodcastsPage';
import GamesPage from './GamesPage';

/**
 * Used to redirect the user to the default path
 */
function RootRedirect() {
  return (
    <Redirect to="/news" />
  );
}

export default function AppPages() {
  return (
    <Switch>
      <Route exact path="/" component={RootRedirect} />
      <Route path="/news" component={NewsPage} />
      <Route path="/podcasts" component={PodcastsPage} />
      <Route path="/games" component={GamesPage} />
    </Switch>
  );
}

import { combineReducers } from 'redux';

import articles from './articles';
import podcasts from './podcasts';
import games from './games';
import ui from './ui';

export default combineReducers({
  articles,
  podcasts,
  games,
  ui,
});

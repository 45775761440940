import {
  FETCH_ARTICLES,
  RECEIVE_ARTICLES,
  SET_ARTICLE_SEARCH_TERM,
  ADD_ARTICLE_CATEGORY,
  REMOVE_ARTICLE_CATEGORY,
  SET_ARTICLE_HOTNESS,
} from '../constants/articles';

import articles from '../fixtures/articles';

export function setSearchTerm(term = '') {
  return {
    type: SET_ARTICLE_SEARCH_TERM,
    payload: {
      term,
    },
  };
}

export function addCategory(category) {
  return {
    type: ADD_ARTICLE_CATEGORY,
    payload: {
      category,
    },
  };
}

export function removeCategory(category) {
  return {
    type: REMOVE_ARTICLE_CATEGORY,
    payload: {
      category,
    },
  };
}

export function setHotness(hotness) {
  return {
    type: SET_ARTICLE_HOTNESS,
    payload: {
      hotness,
    },
  };
}

export function fetchArticles() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ARTICLES,
    });

    setTimeout(() => {
      dispatch({
        type: RECEIVE_ARTICLES,
        payload: {
          articles,
        },
      });
    }, 500);
  };
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Switch, Route, withRouter } from 'react-router';

import MobileBackButton from './MobileBackButton';
import MobileFilterButton from '../containers/MobileFilterButton';

import { appHeader, appHeaderInverted } from '../css/modules/header.module.css';
import { appLogo } from '../css/modules/appLogo.module.css';
import { appNav } from '../css/modules/appNav.module.css';
import { appNavItem, appNavItemActive} from '../css/modules/appNavItem.module.css';
import { mobileNav } from '../css/modules/mobileNav.module.css';

function Header(props) {
  const appHeaderClasses = [appHeader];

  if (props.location.pathname.indexOf("/news/article/") !== -1 || props.location.pathname.indexOf("/podcasts/") !== -1) {
    appHeaderClasses.push(appHeaderInverted);
  }

  return (
    <header className={ appHeaderClasses.join(' ') }>
      <nav className={mobileNav}>
        <Switch>
          <Route path={["/news/article/:id", "/podcasts/:id"]}  component={MobileBackButton} />
          <Route path="*" component={MobileFilterButton} />
        </Switch>
      </nav>
      <nav className={ appNav }>
        <ol>
          <li>
            <NavLink to="/news" className={ appNavItem } activeClassName={ appNavItemActive }>Uudised</NavLink>
          </li>
          <li>
            <NavLink to="/podcasts" className={ appNavItem} activeClassName={ appNavItemActive }>Podcastid</NavLink>
          </li>
          <li>
            <NavLink to="/games" className={ appNavItem } activeClassName={ appNavItemActive }>Mängud</NavLink>
          </li>
        </ol>
      </nav>
      <h1 className={ appLogo }>Senit</h1>
    </header>
  );
}

export default withRouter(Header);

import React from 'react';
import { Route, withRouter } from 'react-router';

import ArticleOverlay from '../containers/ArticleOverlay';
import NewsPageFilter from '../containers/NewsPageFilter';
import NewsPageList from '../containers/NewsPageList';

import { appPage } from '../css/modules/appPage.module.css';

class NewsPage extends React.Component {
  render() {
    return (
      <section className={ appPage }>
        <Route path="/news/article/:id" component={ArticleOverlay} />
        <NewsPageFilter />
        <NewsPageList />
      </section>
    );
  }
}

export default withRouter(NewsPage);
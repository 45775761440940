import React from 'react';
import { connect } from 'react-redux';

import { fetchPodcasts } from '../actions/podcasts';

import PodcastListItem from '../components/PodcastListItem';
import LoadingIndicator from '../components/LoadingIndicator';

import { appContent } from '../css/modules/appContent.module.css';

class PodcastsPageList extends React.Component {
  componentDidMount() {
    this.props.fetchPodcasts();
  }

  render() {
    const { loading, podcasts } = this.props;

    return (
      <main className={ appContent }>
        {
          loading ? <LoadingIndicator /> :
            podcasts.map(a => <PodcastListItem key={a.id} podcast={a} inList />)
        }
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    podcasts: state.podcasts.items,
    loading: state.podcasts.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPodcasts: () => dispatch(fetchPodcasts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastsPageList);
import React from 'react';
import { connect } from 'react-redux';

import { openFilter, closeFilter } from '../actions/ui';

import MenuIcon from '../components/icons/MenuIcon';
import CloseIcon from '../components/icons/CloseIcon';

import { mobileNavButton } from '../css/modules/mobileNavButton.module.css';

function MobileFilterButton(props) {
  return (
    <button onClick={props.filterOpen ? props.closeFilter : props.openFilter} className={mobileNavButton}>
      {
        props.filterOpen ? <CloseIcon /> : <MenuIcon />
      }
    </button>
  );
}

const mapStateToProps = (state) => {
  return {
    filterOpen: state.ui.filterOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFilter: () => dispatch(openFilter()),
    closeFilter: () => dispatch(closeFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilterButton);

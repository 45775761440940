import {
  FETCH_GAMES,
  RECEIVE_GAMES,
  ADD_GAME_CATEGORY,
  REMOVE_GAME_CATEGORY,
} from '../constants/games';

import games from '../fixtures/games';

export function addCategory(category) {
  return {
    type: ADD_GAME_CATEGORY,
    payload: {
      category,
    },
  };
}

export function removeCategory(category) {
  return {
    type: REMOVE_GAME_CATEGORY,
    payload: {
      category,
    },
  };
}

export function fetchGames() {
  return (dispatch) => {
    dispatch({
      type: FETCH_GAMES,
    });

    setTimeout(() => {
      dispatch({
        type: RECEIVE_GAMES,
        payload: {
          games,
        },
      });
    }, 500);
  };
}

import React from 'react';
import { connect } from 'react-redux';

import {
  setSearchTerm,
  addCategory,
  removeCategory,
  setHotness,
  fetchArticles,
} from '../actions/articles';

import FilterSwitch from '../components/FilterSwitch';

import { appSidebar, appSidebarOpen } from '../css/modules/appSidebar.module.css';
import { filterList } from '../css/modules/filterList.module.css';
import { appSearchField } from '../css/modules/appSearchField.module.css';

import categories from '../fixtures/categories';
import hotnessCategories from '../fixtures/hotnessCategories';

class NewsPageFilter extends React.Component {
  changeSearchTerm = (event) => {
    const { setSearchTerm, fetchArticles } = this.props;

    setSearchTerm(event.target.value);
    fetchArticles();
  };

  toggleCategory = (event) => {
    const category = event.target.value;

    if (event.target.checked) {
      this.props.addCategory(category);
    } else {
      this.props.removeCategory(category);
    }

    this.props.fetchArticles();
  };

  setHotness = (event) => {
    if (event.target.checked) {
      this.props.setHotness(event.target.value);
    } else {
      this.props.setHotness(null);
    }

    this.props.fetchArticles();
  };

  render() {
    const {
      filter: { searchTerm, categories: selectedCategories, hotness }, open,
    } = this.props;

    const sidebarClasses = [appSidebar];

    if (open) {
      sidebarClasses.push(appSidebarOpen);
    }

    return (
      <aside className={ sidebarClasses.join(' ') }>
        <form className="searchForm">
          <label htmlFor="app-search-field" className={ appSearchField }>
            <input
              type="search"
              id="app-search-field"
              placeholder="Filter..."
              value={searchTerm}
              onChange={this.changeSearchTerm}
            />
          </label>
          <fieldset>
            <legend>Kategooriad</legend>
            <ul className={filterList}>
              {
                categories.map((c) => (
                  <li key={ c.name }>
                    <FilterSwitch 
                      datakey="categories"
                      value={ c.name }
                      checked={selectedCategories.indexOf(c.name) !== -1}
                      onChange={this.toggleCategory}
                    >
                      { c.value_et }
                    </FilterSwitch>
                  </li>
                ))
              }
            </ul>
          </fieldset>
          <fieldset>
            <legend>Populaarseimad</legend>
            <ul className={filterList}>
              {
                hotnessCategories.map((c) => (
                  <li key={ c.name }>
                    <FilterSwitch
                      datakey="popularity"
                      value={ c.name }
                      checked={hotness === c.name}
                      onChange={this.setHotness}
                    >
                      { c.value_et }
                    </FilterSwitch>
                  </li>
                ))
              }
            </ul>
          </fieldset>
        </form>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filter: state.articles.filter,
    open: state.ui.filterOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchTerm: t => dispatch(setSearchTerm(t)),
    addCategory: c => dispatch(addCategory(c)),
    removeCategory: c => dispatch(removeCategory(c)),
    setHotness: c => dispatch(setHotness(c)),
    fetchArticles: () => dispatch(fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPageFilter);

import React from 'react';
import { connect } from 'react-redux';

import { fetchArticles } from '../actions/articles';

import ArticleListItem from '../components/ArticleListItem';
import LoadingIndicator from '../components/LoadingIndicator';

import { appContent } from '../css/modules/appContent.module.css';

class NewsPageList extends React.Component {
  componentDidMount() {
    this.props.fetchArticles();
  }

  render() {
    const { loading, articles } = this.props;

    return (
      <main className={ appContent }>
        {
          loading ? <LoadingIndicator /> :
            articles.map(a => <ArticleListItem key={a.id} article={a} inList />)
        }
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    articles: state.articles.items,
    loading: state.articles.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: () => dispatch(fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPageList);
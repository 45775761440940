import React, { Component } from 'react';
import { HashRouter as BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import '../css/variables.css';
import '../css/body.css';

import Header from './Header';
import AppPages from './AppPages';

import appReducer from '../reducers';

const store = createStore(appReducer, applyMiddleware(ReduxThunk));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <>
            <Header />
            <AppPages />
          </>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

import React from 'react';

import { filterSwitch } from '../css/modules/filterSwitch.module.css';

export default function FilterSwitch({ key: datakey, value, children, unique = false, checked = false, onChange = () => console.error('No onChange listener provided to FilterSwitch') }) {
  const inputId = `filter-switch-${datakey}-${value}`;

  const inputProps = {
    id: inputId,
    type: unique ? 'radio': 'checkbox',
    name: unique ? `filter-switch-${datakey}` : inputId,
    value,
    checked,
    onChange,
  };

  return (
    <label htmlFor={ inputId } className={ filterSwitch }>
      <input {...inputProps} />
      <span>
        {
          children
        }
      </span>
    </label>
  );
}

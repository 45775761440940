import { OPEN_FILTER, CLOSE_FILTER } from '../constants/ui';

export function openFilter() {
  return {
    type: OPEN_FILTER,
  };
}

export function closeFilter() {
  return {
    type: CLOSE_FILTER,
  };
}

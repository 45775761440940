import { combineReducers } from 'redux';

import {
  FETCH_GAMES,
  RECEIVE_GAMES,
  ADD_GAME_CATEGORY,
  REMOVE_GAME_CATEGORY,
} from '../constants/games';

function loading(state = false, action) {
  switch (action.type) {
    case FETCH_GAMES:
      return true;
    case RECEIVE_GAMES:
      return false;
    default:
      return state;
  }
}

function items(state = [], action) {
  switch (action.type) {
    case RECEIVE_GAMES:
      return action.payload.games;
    default:
      return state;
  }
}

function categories(state = [], action) {
  switch (action.type) {
    case ADD_GAME_CATEGORY:
      return state.concat([action.payload.category]);
    case REMOVE_GAME_CATEGORY:
      return state.filter(c => c !== action.payload.category);
    default:
      return state;
  }
}

const filter = combineReducers({
  categories,
});

export default combineReducers({
  loading,
  items,
  filter,
});

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/et';

import { articleBlock, articleBlockList, articleMedia, articleContent, articleMeta } from '../css/modules/articleBlock.module.css';
import { articleListLink } from '../css/modules/articleListLink.module.css';

moment.locale('et');

function excerpt(text) {
  if (text.length > 200) {
    return text.substring(0, 200) + '…';
  }

  return text;
}

export default function ArticleListItem(props) {
  const {
    article,
  } = props;

  const articleClasses = [articleBlock, articleBlockList];

  return (
    <article className={articleClasses.join(' ')}>
      <Link to={`/news/article/${article.id}`} className={articleListLink}>
        {
          article.image_url && (
            <div className={articleMedia}>
              <img src={ article.image_url } className="article-image" alt="" />
            </div>
          )
        }
        <div className={articleContent}>
          <h1>
            { article.title }
            { ' ' } 
            <span className={articleMeta}>
              {moment(article.datetime).format('D MMM H:mm')}, { article.source }
            </span>
          </h1>
          { excerpt(article.excerpt) }
        </div>
      </Link>
    </article>
  );
}

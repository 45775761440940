import { combineReducers } from 'redux';

import {
  FETCH_ARTICLES,
  RECEIVE_ARTICLES,
  SET_ARTICLE_SEARCH_TERM,
  ADD_ARTICLE_CATEGORY,
  REMOVE_ARTICLE_CATEGORY,
  SET_ARTICLE_HOTNESS,
} from '../constants/articles';

function loading(state = false, action) {
  switch (action.type) {
    case FETCH_ARTICLES:
      return true;
    case RECEIVE_ARTICLES:
      return false;
    default:
      return state;
  }
}

function items(state = [], action) {
  switch (action.type) {
    case RECEIVE_ARTICLES:
      return action.payload.articles;
    default:
      return state;
  }
}

function searchTerm(state = '', action) {
  switch (action.type) {
    case SET_ARTICLE_SEARCH_TERM:
      return action.payload.term;
    default:
      return state;
  }
}

function categories(state = [], action) {
  switch (action.type) {
    case ADD_ARTICLE_CATEGORY:
      return state.concat([action.payload.category]);
    case REMOVE_ARTICLE_CATEGORY:
      return state.filter(c => c !== action.payload.category);
    default:
      return state;
  }
}

function hotness(state = null, action) {
  switch (action.type) {
    case SET_ARTICLE_HOTNESS:
      return action.payload.hotness;
    default:
      return state;
  }
}

const filter = combineReducers({
  searchTerm,
  categories,
  hotness,
});

export default combineReducers({
  loading,
  items,
  filter,
});

import React from 'react';
import { withRouter } from 'react-router';

import CloseIcon from './icons/CloseIcon';

import { mobileNavButton } from '../css/modules/mobileNavButton.module.css';

function MobileBackButton(props) {
  return (
    <button type="button" onClick={props.history.goBack} className={mobileNavButton}>
      <CloseIcon />
    </button>
  );
}

export default withRouter(MobileBackButton);

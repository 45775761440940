import React from 'react';
import { withRouter } from 'react-router';

import GamesPageFilter from '../containers/GamesPageFilter';
import GamesPageList from '../containers/GamesPageList';

import { appPage } from '../css/modules/appPage.module.css';

class PodcastsPage extends React.Component {
  render() {
    return (
      <section className={ appPage }>
        <GamesPageFilter />
        <GamesPageList />
      </section>
    );
  }
}

export default withRouter(PodcastsPage);
import React from 'react';
import { connect } from 'react-redux';

import {
  addCategory,
  removeCategory,
  fetchGames,
} from '../actions/games';

import FilterSwitch from '../components/FilterSwitch';

import { appSidebar, appSidebarOpen } from '../css/modules/appSidebar.module.css';
import { filterList } from '../css/modules/filterList.module.css';

import gameCategories from '../fixtures/gamesCategories';

class GamesPageFilter extends React.Component {
  toggleCategory = (event) => {
    const category = event.target.value;

    if (event.target.checked) {
      this.props.addCategory(category);
    } else {
      this.props.removeCategory(category);
    }

    this.props.fetchGames();
  };

  render() {
    const {
      filter: { categories: selectedCategories }, open,
    } = this.props;

    const sidebarClasses = [appSidebar];

    if (open) {
      sidebarClasses.push(appSidebarOpen);
    }

    return (
      <aside className={ sidebarClasses.join(' ') }>
        <form className="searchForm">
          <fieldset>
            <legend>Kategooriad</legend>
            <ul className={filterList}>
              {
                gameCategories.map((c) => (
                  <li key={ c.name }>
                    <FilterSwitch 
                      datakey="categories"
                      value={ c.name }
                      checked={selectedCategories.indexOf(c.name) !== -1}
                      onChange={this.toggleCategory}
                    >
                      { c.value_et }
                    </FilterSwitch>
                  </li>
                ))
              }
            </ul>
          </fieldset>
        </form>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filter: state.games.filter,
    open: state.ui.filterOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: c => dispatch(addCategory(c)),
    removeCategory: c => dispatch(removeCategory(c)),
    fetchGames: () => dispatch(fetchGames()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesPageFilter);

import { combineReducers } from 'redux';

import {
    FETCH_PODCASTS,
    RECEIVE_PODCASTS,
    SET_PODCAST_SEARCH_TERM,
    ADD_PODCAST_CATEGORY,
    REMOVE_PODCAST_CATEGORY,
    SET_PODCAST_HOTNESS,
} from '../constants/podcasts';

function loading(state = false, action) {
    switch (action.type) {
        case FETCH_PODCASTS:
            return true;
        case RECEIVE_PODCASTS:
            return false;
        default:
            return state;
    }
}

function items(state = [], action) {
    switch (action.type) {
        case RECEIVE_PODCASTS:
            return action.payload.podcasts;
        default:
            return state;
    }
}

function searchTerm(state = '', action) {
    switch (action.type) {
        case SET_PODCAST_SEARCH_TERM:
            return action.payload.term;
        default:
            return state;
    }
}

function categories(state = [], action) {
    switch (action.type) {
        case ADD_PODCAST_CATEGORY:
            return state.concat([action.payload.category]);
        case REMOVE_PODCAST_CATEGORY:
            return state.filter(c => c !== action.payload.category);
        default:
            return state;
    }
}

function hotness(state = null, action) {
    switch (action.type) {
        case SET_PODCAST_HOTNESS:
            return action.payload.hotness;
        default:
            return state;
    }
}

const filter = combineReducers({
    searchTerm,
    categories,
    hotness,
});

export default combineReducers({
    loading,
    items,
    filter,
});

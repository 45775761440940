import React from 'react';
import { Route, withRouter } from 'react-router';

import PodcastOverlay from '../containers/PodcastOverlay';
import PodcastsPageFilter from '../containers/PodcastsPageFilter';
import PodcastsPageList from '../containers/PodcastsPageList';

import { appPage } from '../css/modules/appPage.module.css';

class PodcastsPage extends React.Component {
  render() {
    return (
      <section className={ appPage }>
        <Route path="/podcasts/:id" component={PodcastOverlay} />
        <PodcastsPageFilter />
        <PodcastsPageList />
      </section>
    );
  }
}

export default withRouter(PodcastsPage);
import { combineReducers } from 'redux';

import { OPEN_FILTER, CLOSE_FILTER } from '../constants/ui';

function filterOpen(state = false, action) {
  switch (action.type) {
    case OPEN_FILTER:
      return true;
    case CLOSE_FILTER:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  filterOpen,
});

import React from 'react';

import { gameBlock } from '../css/modules/gameBlock.module.css';

export default function GameListItem({ game }) {
  return (
    <article className={gameBlock}>
      <img src={game.image_url} alt="" />
    </article>
  );
}

import React from 'react';

import LoadingIcon from './icons/LoadingIcon';

import { loadingIndicator } from '../css/modules/loadingIndicator.module.css';

export default function LoadingIndicator() {
  return (
    <div className={loadingIndicator}>
      <LoadingIcon />
    </div>
  );
}
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { articleOverlay, articleOverlayContent, overlayCloseButton } from '../css/modules/articleOverlay.module.css';
import { articleBlock, articleMedia, articleContent, articleMeta, articleExcerpt } from '../css/modules/articleBlock.module.css';
import { shareList } from '../css/modules/shareList.module.css';
import { relatedArticlesList } from '../css/modules/relatedArticlesList.module.css';

import CloseIcon from '../components/icons/CloseIcon';
import FacebookIcon from '../components/icons/FacebookIcon';
import TwitterIcon from '../components/icons/TwitterIcon';
import GmailIcon from '../components/icons/GmailIcon';

import articles from '../fixtures/articles';
import categories from '../fixtures/categories';

function ArticleOverlay(props) {
  const { article } = props;

  return (
    <section className={articleOverlay}>
      <div className={articleOverlayContent}>
        <Link to="/news" className={overlayCloseButton}>
          <CloseIcon />
        </Link>
        <article className={articleBlock}>
          {
            article.image_url && (    
              <div className={ articleMedia }>
                <img src={article.image_url} className="article-image" alt="" />
              </div>
            )
          }
          <div className={ articleContent }>
            <h1>
              { article.title }
              { ' ' } 
              <span className={articleMeta}>
                {moment(article.datetime).format('D MMM H:mm')}, { article.source }
              </span>
            </h1>
            <section className={articleExcerpt}>
              { article.excerpt }
            </section>
            { article.body }
            <h2>
              Jaga artiklit:
            </h2>
            <ul className={shareList}>
              <li><FacebookIcon /></li>
              <li><TwitterIcon /></li>
              <li><GmailIcon /></li>
            </ul>
            <h2>Märksõnad:</h2>
            { article.keywords.map(k => <React.Fragment key={k}><a href="#">{k}</a> </React.Fragment>) }
            <h2>Kategooriad:</h2>
            { article.categories.map(c => <React.Fragment key={c}><a href="#" key={c}>{ categories.find(f => f.name === c).value_et }</a> </React.Fragment>) }
            <h2>Sarnased artiklid:</h2>
            <ol className={relatedArticlesList}>
              <li>
                <h3>
                  Eestlaste leiutatud HIV-vaktsiini arendanud firma läks pankrotti
                  { ' ' }
                  <span className={articleMeta}>
                    märts 12 13:24, Õhtuleht
                  </span>
                </h3>
              </li>
              <li>
                <h3>
                  Kahest kaks: Sildaru valiti taas USA Spordiakadeemia kuu parimaks sportlaseks
                  { ' ' }
                  <span className={articleMeta}>
                    märts 5 23:50, Geenius
                  </span>
                </h3>
              </li>
              <li>
                <h3>
                  Liikumine «Kõigi Eesti» avalikustas algatajate nimed
                  { ' ' }
                  <span className={articleMeta}>
                    veebruar 10 10:15, Facebook
                  </span>
                </h3>
              </li>
            </ol>
          </div>
        </article>
      </div>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    article: articles.find(a => parseInt(a.id, 10) === parseInt(ownProps.match.params.id, 10)),
  };
};

export default connect(mapStateToProps)(withRouter(ArticleOverlay));
